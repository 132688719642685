import React, { useEffect, useState } from "react";
import useFetch from "../api/useFetch";
import Table from "./Table";
import Loading from "./Loading";
import funcs from '../utils/utils'

const Payrolls = () => {
  const { result: payrolls, isLoading: payrollsLoading, error: payrollsError } = useFetch('/payrolls', []);
  const tableHeader = [{ content: "Technician", size: "lg", isLink: false}, { content: "Week", size: "sm", isLink: false}, { content: "Year", size: "sm", isLink: false}, { content: "Amount", size: "sm", isLink: false}, { content: "Details", size: "sm", isLink: true, link: '/payroll'}];
  const [tableContent, setTableContent] = useState(null);
  const [week, setWeek] = useState(37);
  const [year, setYear] = useState(2024);
  const [technician, setTechnician] = useState(null);

  

  useEffect(() => {
    if (payrolls !== null) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setTableContent(
        payrolls.items.map((item) => [
          item.contractor.person.first_name,
          item.week,
          item.year,
          `$${item.amount}`,
          item.id
        ])
      );
    }
  }, [payrolls]);
  return (
  <>
  {payrollsLoading && <Loading />}
  {payrolls && (
    <>
   <div style={{marginTop: '25px'}}className="buttons">
    {<div className="button">
      <div>
      Create Payroll
      </div>
      <br/><br/>
      <div>
      <form>
        <select selected={technician} onChange={(e) => setTechnician(e.target.value)}>
          {payrolls.technicians.map((item) => (
            <option key={item.id} value={item.id}>{item.person.first_name} {item.person.last_name}</option>
          ))}
        </select>
        <br/><br/>
        <select selected={week} onChange={(e) => setWeek(e.target.value)}>
          {funcs.weekSelector()}
        </select>
            <br/><br/>
        <select selected={year} onChange={(e) => setYear(e.target.value)}>
              {funcs.yearSelector()}
            </select>
            <br/><br/>
        <button type="submit">Start</button>
      </form>
      </div>
    </div>}
  </div>
  <div div className="list">
    <div className="title">PAYROLLS</div>

      {payrolls && <Table tableHeader={tableHeader} tableContent={tableContent} />}
      {payrollsLoading && <Loading />}
      {payrollsError && <>{payrollsError}</>}
  </div>
  </>
  )
  }
  </>
  )
}

export default Payrolls